/**
 * Consolidated error handling for similar cases.
 * @param {Object} error - The error object.
 */
async function handleErrorByType(error) {

    /**
     * flow is that we first check if error still exists.
     * if it exists, then we fix it.
     * else we do nothing.
    */

    switch (error.error_code) {
        
        // 4xx - DO NOT FIX
        case 1:
            // window.location.replace(error?.error_suggestion.trim());
            break;
        
        // 5xx - DO NOT FIX
        case 2:
            // window.location.replace(error?.error_suggestion.trim());
            break;
    
        // meta title - missing
        case 3:
            let t = document.querySelector('title');
            if (!t) {
                t = document.createElement('title');
                document.getElementsByTagName('head')[0].appendChild(t);
                t.textContent = error?.error_suggestion;
                console.log("[PF] fixed missing title tag...");
            } else {
                console.log("[PF] meta title exists on page, not fixing missing meta title...")
            }
            break;
        
        // meta title - multiple
        case 4:
            let titleElements = document.getElementsByTagName('title');
            if (titleElements.length < 2){
                console.log("[PF] only one meta title found on page, not fixing multiple meta titles...")
                return;
            }
            // At least two title tag exists
            // Multiple title tags, remove extras
            for (let i = titleElements.length - 1; i > 0; i--) {
                titleElements[i].parentNode.removeChild(titleElements[i]);
            }            
            // Set content of remaining title tag
            titleElements[0].textContent = error?.error_suggestion;
            console.log("[PF] Fixed multiple title tags.");
            break;
        
        // meta title - too short
        case 5:
            let t1 = document.querySelector('title');
            if (t1 && t1.textContent && t1.textContent.length < 10) {
                t1.textContent = error?.error_suggestion.trim();
                console.log("[PF] fixed title tag (too short)...");
            } else {
                console.log("[PF] not fixing meta title too short, error doesn't exist anymore...");
            }
            break;
        
        // meta title - too long
        case 6:
            let t2 = document.querySelector('title');
            if (t2 && t2.textContent && t2.textContent.length > 60) {
                t2.textContent = error?.error_suggestion.trim();
                console.log("[PF] fixed title tag (too long)...");
            } else {
                console.log("[PF] not fixing meta title too long, error doesn't exist anymore...");
            }
            break;
        
        // meta description - missing
        case 7:
            let m = document.querySelector('meta[name="description"]');
            if (!m) {
                m = document.createElement('meta');
                m.name = "description";
                document.getElementsByTagName('head')[0].appendChild(m);
                m.content = error?.error_suggestion;
                console.log("[PF] fixed missing meta description...");
            } else {
                console.log("[PF] found meta description, not fixing missing meta description...");
            }
            break;
        
        // meta description - multiple
        case 8:
            let metaDescriptions = document.querySelectorAll('meta[name="description"]');
            if (metaDescriptions.length < 2){
                console.log("[PF] found only one meta description, not fixing multiple meta descriptions...");
                return;
            }
            // At least two meta descriptions exists
            for (let i = metaDescriptions.length - 1; i > 0; i--) {
                metaDescriptions[i].parentNode.removeChild(metaDescriptions[i]);
            }            
            // Set content of remaining title tag
            metaDescriptions[0].content = error?.error_suggestion;
            console.log("[PF] Fixed multiple meta descriptions.");
            break;

        // meta description - too short
        case 27:
            let m2 = document.querySelector('meta[name="description"]');
            if (m2 && m2.content && m2.content.length < 50) {
                m2.content = error?.error_suggestion;
                console.log("[PF] fixed missing meta description (too short)...");
            } else {
                console.log("[PF] meta description too short error doesn't exist anymore...");
            }
            break;

        // meta description - too long
        case 28:
            let m3 = document.querySelector('meta[name="description"]');
            if (m3 && m3.content && m3.content.length > 150) {
                m3.content = error?.error_suggestion;
                console.log("[PF] fixed missing meta description (too long)...");
            } else {
                console.log("[PF] meta description too long error doesn't exist anymore...");
            }
            break;

        // og title - missing
        case 30:
            const ogTitle = document.querySelector('meta[property="og:title"]');
            if (!ogTitle) {
                const newOgTitle = document.createElement('meta');
                newOgTitle.setAttribute('property', 'og:title');
                newOgTitle.setAttribute('content', error?.error_suggestion);
                document.head.appendChild(newOgTitle);
                console.log('[PF] fixed missing open graph title...');
            } else {
                console.log("[PF] og title found. not fixing missing og title...");
            }
            break;

        // og description - missing
        case 31:
            const ogDescription = document.querySelector('meta[property="og:description"]');
            if (!ogDescription) {
                const newOgDescription = document.createElement('meta');
                newOgDescription.setAttribute('property', 'og:description');
                newOgDescription.setAttribute('content', error?.error_suggestion);            
                document.head.appendChild(newOgDescription);
                console.log('[PF] fixed missing open graph description...');
            } else {
                console.log("[PF] og description found. not fixing missing og description...");
            }
            break;

        // h1 - missing
        case 10:
            let h1Tags = document.body.getElementsByTagName('h1');
            if (h1Tags.length === 0) {
                // No h1 tag exists, create one
                let newH1 = document.createElement('h1');
                newH1.textContent = error?.error_suggestion;
                newH1.style.display = 'none';  // Make it hidden
                // Insert the new h1 at the beginning of the body
                document.body.insertBefore(newH1, document.body.firstChild);
                console.log("[PF] Fixed missing H1...");
            } else {
                console.log("[PF] h1 found. not fixing missing h1 tags...");
            }
            break;

        // h1 - multiple
        case 11:
            let h1Tags1 = document.getElementsByTagName('h1');
            if (h1Tags1.length >= 2) {
                // Convert NodeList to Array and skip the first element
                Array.from(h1Tags1).slice(1).forEach((h1, index) => {
                    // Create a new h2 element
                    let h2 = document.createElement('h2');
                    // Copy all attributes from h1 to h2
                    for (let attr of h1.attributes) {
                        h2.setAttribute(attr.name, attr.value);
                    }
                    // Copy the inline style
                    h2.style.cssText = h1.style.cssText;
                    // Copy the class list
                    h2.className = h1.className;
                    // Copy the content
                    h2.innerHTML = h1.innerHTML;
                    // Replace h1 with h2
                    h1.parentNode.replaceChild(h2, h1);
                });
                console.log("[PF] Fixed multiple H1 tags...")
            } else {
                console.log("[PF] did not fond multiple h1s. not fixing the error...");
            }
            break;

        // h1 - overlap with title
        case 12:
            let overlapTitle = document.querySelector('title');
            if (overlapTitle) {
                overlapTitle.textContent = error?.error_suggestion.trim();
                console.log("[PF] fixed H1 and Title overlap...");
            } 
            break;

        // canonical - missing
        case 13:
            let c = document.querySelector('link[rel="canonical"]');
            if (!c) {
                // Canonical tag doesn't exist, create one
                let newCanonical = document.createElement('link');
                newCanonical.rel = 'canonical';
                newCanonical.href = error?.error_suggestion;
                // Add the new canonical tag to the head
                document.head.appendChild(newCanonical);
                console.log("[PF] Fixed missing canonical tag...");
            }
            break;

        // canonical - multiple
        case 14:
            let canonicalTags = document.querySelectorAll('link[rel="canonical"]');
            if (canonicalTags.length > 1) {
                // Remove all other canonical tags
                for (let i = 1; i < canonicalTags.length; i++) {
                    canonicalTags[i].parentNode.removeChild(canonicalTags[i]);
                }
                console.log("[PF] Fixed multiple canonical tags...");
            }
            break;

        // canonical - broken - NOT FIXING
        case 15:
            // let c1 = document.querySelector('link[rel="canonical"]');
            // if (c1) {
            //     c1.href = error?.error_suggestion;
            //     console.log("[PF] Fixed broken canonical tag...");
            // }
            break;

        // canonical - disallowed - NOT FIXING 
        case 16:
            // let c2 = document.querySelector('link[rel="canonical"]');
            // if (c2) {
            //     c2.href = error?.error_suggestion;
            //     console.log("[PF] Fixed disallowed canonical tag...");
            // }
            break;

        // image alt - missing
        case 17:
            let img = document.querySelector(`img[src="${error.data.image.src}"]`);
            if (img) {        
                if (!img.hasAttribute('alt') || img.alt.trim() === '') {
                    img.alt = error?.error_suggestion;
                    console.log("[PF] Fixed missing alt text...");
                } 
            } 
            break;

        // image - broken - NOT FIXING
        case 26:
            // let img1 = document.querySelector(`img[src="${error.data.image.src}"]`);
            // if (img1) {        
            //     if (!img1.hasAttribute('alt') || img1.alt.trim() === '') {
            //         img1.alt = error?.error_suggestion;
            //         console.log("[PF] Fixed missing alt text...");
            //     } 
            // } 
            break;

        // link - no anchor text - NOT FIXING
        case 18:
            // let href1 = error.data.link.href;
            // const l1 = document.querySelector(`a[href="${href1}"]`);
            // if (l1){
            //     if (!l1.textContent.trim()){
            //         l1.textContent = error?.error_suggestion;
            //         console.log("[PF] Fixed missing anchor text...")
            //     }
            // }
            break;

        // link - nofollow in internal link
        case 19:
            let href2 = error.data.link.href;
            const l2 = document.querySelector(`a[href="${href2}"]`);
            if (l2 && l2.getAttribute){
                let relValue = l2.getAttribute("rel");
                if (relValue.includes('nofollow')){
                    let newRelValues = relValue.split(' ').filter(value => value.toLowerCase() !== 'nofollow');
                    if (newRelValues.length > 0){
                        l2.setAttribute('rel', newRelValues.join(' '));
                    } else {
                        l2.removeAttribute('rel');
                    }
                    console.log("[PF] Fixed internal link with nofollow attribute...");
                }
            }
            break;

        // link - broken internal NOT FIXING 
        case 20:
            // let href3 = error.data.link.href;
            // const l3 = document.querySelector(`a[href="${href3}"]`);
            // if (l3){
            //     l3.href = error?.error_suggestion;
            //     console.log("[PF] Fixed broken internal link")
            // }
            break;

        // link - broken external NOT FIXING
        case 21:
            // let href4 = error.data.link.href;
            // const l4 = document.querySelector(`a[href="${href4}"]`);
            // if (l4){
            //     l4.href = error?.error_suggestion;
            //     console.log("[PF] Fixed broken external link")
            // }
            break;

        // link - insecure internal
        case 22:
            let href5 = error.data.link.href;
            const l5 = document.querySelector(`a[href="${href5}"]`);
            if (l5){
                l5.href = error?.error_suggestion;
                console.log("[PF] Fixed insecure internal link")
            }
            break;

        // link - insecure external
        case 23:
            let href6 = error.data.link.href;
            const l6 = document.querySelector(`a[href="${href6}"]`);
            if (l6){
                l6.href = error?.error_suggestion;
                console.log("[PF] Fixed insecure external link")
            }
            break;

        // duplicate meta tags - meta title conflict - NOT FIXING
        case 24:
            break;

        // duplicate meta tags - meta description conflict - NOT FIXING
        case 25:
            break;
        default:
            console.log(`[PF] not handling error`, error);
            break;
    }
}


/**
 * finds the audit associated with current page.
 * @todo this needs to change to use sql.
 */
async function getAudit({ userId, websiteId, page_url }){
    try {

        // New SQL-based route
        let baseUrl = `https://api.getpassionfruit.tech`;
        console.log("[PF] Fetching audit (SQL) for :", page_url);

        let response = await fetch(
            `${baseUrl}/api/v1/guru-meta-data/errorsByPage?userId=${userId}&websiteId=${websiteId}&page_url=${encodeURIComponent(page_url)}`, 
            {
                method: 'GET',
                headers : {
                    'Content-Type': 'application/json'
                }
            }
        );
        
        if (!response.ok){
            console.error("[PF] Unable to fetch latest audit date ");
            return null;
        }
        console.log("[PF] Successfully fetched latest audit date...");
        let jsonData = await response.json();
        if (!jsonData || !jsonData.data){
            console.log("[PF] Response data empty");
            return null;
        }
        // to keep structure: { errors: [...] }
        return { errors: jsonData.data };
    } catch (err){
        console.error("[PF] Unable to fetch latest audit date", err);
        return null;
    }
}

/**
 * Main function to run the script.
 */
async function initScript() {

    /**
     * PF script should be in DOM with this id
     * the DOM object has userId, websiteId passed with it.
     * page url you need to get from window.location.href.
    */
    const script = document.getElementById('pf-guru-script');
    if(!script){
        console.log('[PF] Script not found');
        return;
    }
    const userId = script?.dataset?.uid;
    const websiteId = script?.dataset?.wid;
    let page_url = window.location.href;

    // internal testing, do not edit.
    if (page_url.includes("localhost")){
        page_url = `https://passionfruit-guru.vercel.app` + window.location.pathname
    }

    // all console logs must begin with "[PF]"
    console.log("[PF] script loaded...");

    ///////////////////////////////////// >>>>>>>>>>>>>>>> start

    /**
     * @todo we need to change this parts below.
     * 1. first find the max date from guru_errors for this websiteId, userId combination
     * 2. find the errors on this url with the above max date, userId, websiteId, apply_suggestion, is_successful, error_suggestion is not null.
    */

    let audit = await getAudit({ 
        userId, 
        websiteId, 
        page_url : page_url.replace(/\/+$/, '')  // remove trailing slash
    });

    if (!audit){
        console.log("[PF] Not making changes (audit).");
        return;
    }

    let errors = audit.errors.filter(obj => obj.apply_suggestion && obj.error_suggestion && obj.error_suggestion.length > 0 && obj.is_successful);
    
    ///////////////////////////////////// >>>>>>>>>>>>>>>> end

    if (errors.length < 1){
        console.log("[PF] Not making changes (no errors / no errors to be fixed).")
        return;
    }

    console.log("[PF] Found errors to fix", errors);

    errors.forEach(async (error) => {
        await handleErrorByType(error);
    })

}


// Start running the experiment when DOM is ready
document.addEventListener('DOMContentLoaded', async () => {
    console.log("[PF] DOM content loaded...");
    await initScript();
});
